import { useParams } from "react-router-dom";
import Breadcrumbs from "../../../components/breadcrumbs";
import "./styles.css";
import SEO from "../../../components/seo";
import { useEffect, useState } from "react";
import BrandService from "../../../domain/services/brand-service";
import BrandInformation from "../../../components/brands/brand-information";
import IApiStatus from "../../../domain/entities/api/IApiStatus";
import IBrandFull from "../../../domain/entities/brand/IBrandFull";
import ProductsList from "../../../components/products-list";
import ProductService from "../../../domain/services/product-service";
import IProductMini from "../../../domain/entities/product/IProductMini";
import IProductFilter from "../../../domain/entities/product/IProductFilter";
import ErrorComponent from "../../../components/error";
import SkeletonLoading from "../../../components/loading/skeleton-loading";
import ISearchProductRequest from "../../../domain/entities/product/ISearchProductRequest";
import Error404Page from "../../error/error404";

const brandService = new BrandService();
const productService = new ProductService();

export default function BrandPage() {
  const { slug } = useParams();
  const [brand, setBrand] = useState<IBrandFull | undefined>(undefined);
  const [products, setProducts] = useState<IProductMini[]>([]);
  const [filters, setFilters] = useState<IProductFilter | undefined>(undefined);
  const [apiStatus, setApiStatus] = useState<IApiStatus>({
    status: false,
    message: "",
    loading: true,
  });
  const [productApiStatus, setProductApiStatus] = useState<IApiStatus>({
    status: false,
    message: "",
    loading: true,
  });
  const [loadedPage, setLoadedPage] = useState(1);
  const [customPage, setCustomPage] = useState(0);
  const [allPagesLoaded, setAllPagesLoaded] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [currentFilters, setCurrentFilters] = useState<ISearchProductRequest>({
    page_limit: 20,
    page_number: 1,
  });
  const [notFound, setNotFound] = useState(false);

  useEffect(() => {
    async function fetchBrandData() {
      if (!slug) {
        setApiStatus({
          status: false,
          message: "Brand not found",
          loading: false,
        });
        return;
      }
      const response = await brandService.getBrand(slug);
      if (!response || !response.status || !response.data) {
        if (response?.data === false) setNotFound(true);
        setApiStatus({
          status: false,
          message: response.message || "Brand not found",
          loading: false,
        });
        return;
      }

      setApiStatus({
        status: response.status,
        message: response.message,
        loading: false,
      });
      setBrand(response.data);
    }

    fetchBrandData();
    searchProducts(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug]);

  useEffect(() => {
    if (customPage === loadedPage) {
      searchProducts(customPage, true, true);
      setCustomPage(0);

      return;
    }
    if (loadedPage <= 1) return;
    searchProducts(loadedPage, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadedPage]);

  useEffect(() => {
    if (productApiStatus.loading) return;
    searchProducts(1, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFilters]);

  async function searchProducts(
    page: number,
    keepFilters?: true,
    removeProducts?: true
  ) {
    try {
      if (!slug) {
        setProductApiStatus({
          status: false,
          message: "Brand not found",
          loading: false,
        });
        return;
      }
      setProductApiStatus({
        loading: true,
        message: "",
        status: false,
      });
      if (page === 1) {
        setLoadedPage(1);
        setProducts([]);
        if (!keepFilters) setFilters(undefined);
        setAllPagesLoaded(false);
      }
      if (removeProducts) {
        setProducts([]);
      }

      const response = await productService.getProductsFromBrand(slug, {
        ...currentFilters,
        brand_slugs: [],
        page_limit: 20,
        page_number: page,
      });
      if (!response || !response.status || !response.data) {
        setProductApiStatus({
          status: false,
          message: response.message || "Brand not found",
          loading: false,
        });
        return;
      }

      if (response.pagination?.lastPage || response.data?.products.length === 0)
        setAllPagesLoaded(true);
      setProducts((previousProducts) => [
        ...previousProducts,
        ...(response.data?.products || []),
      ]);
      if (!keepFilters) setFilters(response.data.filters);
      if (page === 1) setTotalPages(response.pagination?.totalPages || 1);
      setProductApiStatus({
        status: response.status,
        message: response.message,
        loading: false,
      });
    } catch (error) {
      setProductApiStatus({
        status: false,
        message: "An error occurred while fetching products.",
        loading: false,
      });
    }
  }

  if (notFound) return <Error404Page />;

  return (
    <div className="brand-page">
      <SEO
        title={brand ? brand.name : ""}
        description={
          brand
            ? brand.description ||
              `Explore products from ${brand.name} on BeauteByYou`
            : ""
        }
      />
      <Breadcrumbs
        data={[
          {
            to: "/brands",
            label: "All Brands",
          },
          {
            to: window.location.pathname,
            label: brand ? brand.name : "Please Wait",
          },
        ]}
      />
      {apiStatus.loading ? (
        <div className="brand-information">
          <div className="brand-banner">
            <SkeletonLoading />
          </div>
          <div className="brand-profile">
            {/* <div className="brand-logo">
              <SkeletonLoading />
            </div> */}
            <div className="brand-details">
              <SkeletonLoading />
            </div>
          </div>
        </div>
      ) : (
        !apiStatus.status && (
          <ErrorComponent
            title="An error occurred"
            description="An error occurred while fetching the brand"
            errorCode={apiStatus.message}
          />
        )
      )}
      {brand && <BrandInformation data={brand} />}
      {productApiStatus.loading ? null : !apiStatus.status ? null : !productApiStatus.status ? (
        <ErrorComponent
          title="An error occurred"
          description="An error occurred while fetching the products for the brand"
          errorCode={productApiStatus.message}
        />
      ) : null}
      <ProductsList
        heading=""
        filters={filters}
        products={products}
        isLoading={productApiStatus.loading}
        loadNextPage={() => {
          setLoadedPage((prevLoadedPage) => prevLoadedPage + 1);
        }}
        setPage={(page) => {
          setCustomPage(page);
          setLoadedPage(page);
        }}
        allPagesLoaded={allPagesLoaded}
        setCurrentFilters={setCurrentFilters}
        currentPage={loadedPage}
        totalPages={totalPages}
        productService={productService}
      />
    </div>
  );
}
