import "./styles.css";
import { useState } from "react";
import UserRepository from "../../../domain/repositories/user-repository";

const validateEmail = (email: string) => {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return re.test(String(email).toLowerCase());
};

export default function FooterNewsletter() {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState({ text: "", type: "" });
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setMessage({ text: "", type: "" });
    if (!email || !validateEmail(email)) {
      setMessage({ text: "Please enter a valid email address.", type: "error" });
      return;
    }
    setLoading(true);
    const userRepository = new UserRepository();
    try {
      const response = await userRepository.subscribeNewsletter(email);
      setMessage({
        text: response.message,
        type: response.status ? "success" : "error",
      });
    } catch (error) {
      setMessage({ text: "An error occurred. Please try again later.", type: "error" });
    }
    setLoading(false);
  };

  return (
    <div className="footer-newsletter">
      <h2>Subscribe to our newsletter</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="Enter your email address"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          disabled={loading}
        />
        <button type="submit" disabled={loading}>
          {loading ? "Please Wait..." : "Subscribe Now"}
        </button>
      </form>
      {message.text && <p className={`subscription-message ${message.type}`}>{message.text}</p>}
    </div>
  );
}
