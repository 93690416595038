import StarRatings from "react-star-ratings";
import IProductMini from "../../../domain/entities/product/IProductMini";
import "./styles.css";
import { Link, NavLink } from "react-router-dom";
import shortenQuantityToString from "../../../utilities/text/shorten-quantity-to-string";
import { forwardRef, useState } from "react";
import { useUserContext } from "../../../contexts/user";
import ProductService from "../../../domain/services/product-service";
import HeartIcon from "../../../assets/icons/heart";

interface IProductsListItemProps {
  data: IProductMini;
  productService: ProductService;
  fromWishlist?: boolean;
}

const MAX_VARIANTS_DISPLAY = 4;

const ProductsListItem = forwardRef<any, IProductsListItemProps>(
  ({ data, productService, fromWishlist }, ref) => {
    const { isLoggedIn, setWishlistCount } = useUserContext();
    const [inWishlist, setInWishlist] = useState(false);

    async function addToWishlist() {
      setInWishlist(true);
      await productService.addProductToWishlist(data.slug);
      setWishlistCount((prev) => prev + 1);
    }

    return (
      <div className="products-list-item" ref={ref}>
        <div className="products-list-item-image">
          {!inWishlist && !fromWishlist && (
            <div
              className="products-list-item-image-hover"
              title={`Add to your wishlist (${data.name})`}
            >
              {isLoggedIn ? (
                <div className="add-to-wishlist" onClick={addToWishlist}>
                  <HeartIcon />
                </div>
              ) : (
                <NavLink
                  to="/account/login"
                  className="add-to-wishlist"
                  state={{ from: window.location.pathname }}
                >
                  <div className="add-to-wishlist">
                    <HeartIcon />
                  </div>
                </NavLink>
              )}
              <div className="grey-out"></div>
            </div>
          )}
          <img
            src={encodeURI(data.images[0])}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = "/assets/placeholders/product.svg";
            }}
            alt="Product"
          />
        </div>
        <div className="products-list-item-name">
          <NavLink
            to={`/products/${data.slug}`}
            state={{ from: window.location.pathname }}
          >
            {data.name}
          </NavLink>
        </div>
        <div className="products-list-item-details">
          {data.variants.length > 1 ? (
            <div className="variants">
              {data.variants
                .filter((d, i) => i < MAX_VARIANTS_DISPLAY)
                .map((variant, i) => (
                  <Link
                    to={`/products/${data.slug}?variant=${variant.id}`}
                    state={{
                      from: window.location.pathname,
                    }}
                    key={`variant-${data.slug}-${i}`}
                  >
                    <div
                      className="variant-item"
                      style={{
                        backgroundColor: variant.value,
                      }}
                      title={variant.name}
                    ></div>
                  </Link>
                ))}
              {data.variants.length > MAX_VARIANTS_DISPLAY && (
                <Link
                  to={`/products/${data.slug}`}
                  state={{
                    from: window.location.pathname,
                  }}
                >
                  <div
                    className="variant-item"
                    title={`${
                      data.variants.length - MAX_VARIANTS_DISPLAY
                    } more shades available`}
                    style={{
                      border: "none",
                    }}
                  >
                    +{data.variants.length - MAX_VARIANTS_DISPLAY}
                  </div>
                </Link>
              )}
            </div>
          ) : (
            <div className="variants"></div>
          )}
          {data.caption && (
            <div className="products-list-item-caption">{data.caption}</div>
          )}
          <div className="products-list-item-rating">
            <StarRatings
              rating={data.rating}
              starRatedColor="var(--theme-color)"
              numberOfStars={5}
              starDimension="15px"
              starSpacing="0px"
            />
            ({shortenQuantityToString(data.reviews)})
          </div>
          <div className="products-list-item-price">
            From{" "}
            <span className="theme-color">
              {data.currency}
              {data.price.toFixed(2)}
            </span>
          </div>
        </div>
        <div className="product-action-button">
          <NavLink
            to={`/products/${data.slug}`}
            state={{
              from: window.location.pathname,
            }}
          >
            Find Out More
          </NavLink>
        </div>
      </div>
    );
  }
);

export default ProductsListItem;
