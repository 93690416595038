import { useParams } from "react-router-dom";
import "./styles.css";
import ProductsList from "../../../components/products-list";
import SEO from "../../../components/seo";
import capitalizeEachWord from "../../../utilities/text/capitalize-each-word";
import Breadcrumbs from "../../../components/breadcrumbs";
import ProductService from "../../../domain/services/product-service";
import IProductMini from "../../../domain/entities/product/IProductMini";
import { useEffect, useState } from "react";
import IApiStatus from "../../../domain/entities/api/IApiStatus";
import IProductFilter from "../../../domain/entities/product/IProductFilter";
import ErrorComponent from "../../../components/error";
import ISearchProductRequest from "../../../domain/entities/product/ISearchProductRequest";

const productService = new ProductService();

export default function SearchResultsPage() {
  const { query } = useParams();
  const [products, setProducts] = useState<IProductMini[]>([]);
  const [filters, setFilters] = useState<IProductFilter | undefined>(undefined);
  const [apiStatus, setApiStatus] = useState<IApiStatus>({
    loading: true,
    status: false,
    message: "",
  });
  const [loadedPage, setLoadedPage] = useState(1);
  const [totalResults, setTotalResults] = useState(0);
  const [customPage, setCustomPage] = useState(0);
  const [allPagesLoaded, setAllPagesLoaded] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [currentFilters, setCurrentFilters] = useState<ISearchProductRequest>({
    page_limit: 20,
    page_number: 1,
  });

  async function searchProducts(
    page: number,
    keepFilters?: true,
    removeProducts?: true
  ): Promise<void> {
    try {
      setApiStatus({
        loading: true,
        status: false,
        message: "",
      });
      if (page === 1) {
        setLoadedPage(1);
        setProducts([]);
        if (!keepFilters) setFilters(undefined);
        setAllPagesLoaded(false);
      }
      if (removeProducts) setProducts([]);

      const response = await productService.searchProducts({
        ...currentFilters,
        query,
        page_limit: 20,
        page_number: page,
      });

      if (!response || !response.status || !response.data) {
        setApiStatus({
          loading: false,
          message:
            response.message || "An error occurred while fetching products",
          status: false,
        });

        return;
      }

      if (response.pagination?.lastPage || response.data?.products.length === 0)
        setAllPagesLoaded(true);
      if (response.pagination?.total)
        setTotalResults(response.pagination.total);
      if (page === 1) setTotalPages(response.pagination?.totalPages || 1);
      setProducts((previousProducts) => [
        ...previousProducts,
        ...(response.data?.products || []),
      ]);
      if ((page === 1 && !keepFilters) || !filters)
        setFilters(response.data.filters);
      setApiStatus({
        loading: false,
        status: response.status,
        message: response.message,
      });
    } catch (error) {
      setApiStatus({
        loading: false,
        status: false,
        message: "An error occurred while searching for products.",
      });
    }
  }

  useEffect(() => {
    if (customPage === loadedPage) {
      searchProducts(customPage, true, true);
      setCustomPage(0);

      return;
    }
    if (loadedPage <= 1) return;
    searchProducts(loadedPage, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadedPage]);

  useEffect(() => {
    setFilters(undefined);
    setCurrentFilters({
      page_limit: 20,
      page_number: 1,
    });
    if (apiStatus.loading) searchProducts(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  useEffect(() => {
    if (apiStatus.loading) return;
    searchProducts(1, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFilters]);

  return (
    <div className="search-results-page">
      <SEO
        title={`${capitalizeEachWord(query || "")} - Search Results`}
        description="Search for your favorite products on BeauteByYou."
      />
      <Breadcrumbs
        data={[
          {
            label: `Search Results for "${capitalizeEachWord(query || "")}"`,
            to: `/search/${query}`,
          },
        ]}
      />

      <ProductsList
        heading={
          apiStatus.loading
            ? `Search Results for "${capitalizeEachWord(query || "")}"`
            : `Found ${totalResults} results for "${capitalizeEachWord(
                query || ""
              )}"`
        }
        filters={filters}
        products={products}
        isLoading={apiStatus.loading}
        loadNextPage={() => {
          setLoadedPage((prevLoadedPage) => prevLoadedPage + 1);
        }}
        setPage={(page) => {
          setCustomPage(page);
          setLoadedPage(page);
        }}
        allPagesLoaded={allPagesLoaded}
        currentPage={loadedPage}
        totalPages={totalPages}
        setCurrentFilters={setCurrentFilters}
        productService={productService}
      />

      {apiStatus.loading ? null : !apiStatus.status ? (
        <ErrorComponent
          title="An error occurred"
          description="An error occurred while searching for products."
          errorCode={apiStatus.message}
        />
      ) : null}
    </div>
  );
}
